<template>
  <error :error="{ statusCode: 404 }" />
</template>

<script>
import error from '~/layouts/error.vue'

export default {
  components: {
    error,
  },
  fetch({ store }) {
    return store.dispatch('contentful/fetchLayoutData')
  },
  mounted() {
    const { $store, $route } = this
    const statusCode = 404
    const msg = this.$t(`organisms.error_box.${statusCode}.title`)
    this.$gtmEnhanced.trackPageError($route, $store, {
      code: statusCode,
      msg,
      type: 'not_found',
    })
  },
}
</script>
